import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

export default function BlogList(props) {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 768) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              description
            }
          }
        }
      }
    }
  `)
  const posts = data.allMarkdownRemark.edges
  return (
    <div className="collection">
      <div className="collection__header">
        <h2>Words</h2>
      </div>

      <div className="collection__items posts">
        {posts.map((post, key) => {
          return (
            <div className="collection__item" key={key}>
              <Link
                to={`/blog${post.node.fields.slug}`}
                className="post-link-wrapper"
              >
                <Img
                  fluid={
                    post.node.frontmatter.featuredImage.childImageSharp.fluid
                  }
                  className="post-image"
                />
                <div className="post-meta">
                  <h3 className="post-meta__title">
                    {post.node.frontmatter.title}
                  </h3>
                  <p className="post-meta__date">
                    {post.node.frontmatter.date}
                  </p>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}
