import React from "react"
import Header from "../components/header"
import Seo from "../components/seo"
import Divider from "../components/divider"
import BlogList from "../components/bloglist"
import Footer from "../components/footer"

export default function Blog(props) {
  return (
    <>
      <Seo />

      <Header />
      <Divider />

      <section className="recent-work">
        <BlogList />
      </section>
      <Footer />
    </>
  )
}
